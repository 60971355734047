import React, { useEffect, useState } from 'react';
import {
  Page,
  FlexWrapper,
  PopConfirm,
} from '@/components';
import {
  StyledCardHeader,
  StyledProjectCard,
  StyledListItem,
} from '@/components/project-card/project-card.styled';
import { ROUTES } from '@/constants';
import { useProjectStore } from '@/stores';
import { Box, Typography, ToggleButton, ToggleButtonGroup, styled, CardContent, IconButton, ListItemText, ListItemIcon, List, Menu } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { ThreeDots, Hub, Sensor, CableUSB, WirelessConnection, CableConnection, Battery, SensorIn } from '@/components/icons';
import { useDateTimeFormat } from '@/hooks';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { StyledMenuItem } from '@/components/pour-card';
import { toast } from 'react-toastify';
import { routesDetails } from '@/constants';
import { t } from 'i18next';


const StyledToggleButton = styled(ToggleButtonGroup)(({ theme }) => ({
  borderRadius: theme.typography.pxToRem(24),
  width: '100%',
  flexWrap: 'wrap',
  '& .MuiButtonBase-root.MuiButtonBase-root': {
    backgroundColor: theme.palette.grey.A100,
    border: 0,
    minWidth: '63px',
    margin: '5px 1.5px',
    borderRadius: theme.typography.pxToRem(24),
    color: theme.palette.black.light,
    textTransform: 'capitalize',
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(12)}`,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.darker,
      color: theme.palette.common.white,
    },
    '&:last-child': {
      marginRight: '0',
      marginLeft: '0',
    },
  },
}));


const SensorDetails = ({ sensors }) => {
  const { formatDate } = useDateTimeFormat();
  // Function to determine battery color based on percentage
  const getBatteryColorPercentage = (percentage) => {
    if (percentage === null || percentage === undefined) {
      return '#CECECE'; // Default color if no battery value
    }
    if (percentage < 40) {
      return '#bc544b'; // Red color for battery < 40%
    } else if (percentage < 80) {
      return '#cda984'; // Yellow color for 40% <= battery < 80%
    } else {
      return '#009999'; // Green color for battery >= 80%
    }
  };

  const sortedSensors = sensors.sort((a, b) => {
    if (a.active !== b.active) {
      return b.active - a.active; // Sort by active status (true/false)
    }
    return a.device_number - b.device_number; // Sort by device number numerically
  });

  return (
    <Box>
      {sortedSensors.map((sensor, i) => {
        const sensorName = sensor.device_number ? sensor.device_number : sensor.display_name
        const defaultColor = '#CECECE';
        const activeColor = sensor.active ? '#009999' : defaultColor; // Green if active, grey if not
        const connectedColor = sensor.active && sensor.connected ? activeColor : defaultColor;
        const inConcreteColor = sensor.active && sensor.in_concrete ? activeColor : defaultColor;
        const communicationColor = sensor.active && sensor.communication ? activeColor : (sensor.active ? '#bc544b' : defaultColor);
        const batteryValue = sensor.sensor_battery ?? 'undefined';
        const batteryColor = sensor.active
          ? sensor.device_number !== null 
            ? (batteryValue === null || batteryValue === 'undefined' ? defaultColor : getBatteryColorPercentage(batteryValue))
            : (batteryValue === null || batteryValue === 'undefined' ? defaultColor : '#696868')
          : defaultColor;
        const batterySymbol = sensor.device_number !== null ? '%' : 'mV';
        const displayLastBroadcast = sensor.active ? formatDate(sensor.db_ts_tz, 'time') : '';
        const lastBroadcast = displayLastBroadcast !== 'N/A' ? displayLastBroadcast : '';
        const headerBackgroundColor = sensor.active ? undefined : defaultColor;
        
        

        return (
          <StyledProjectCard key={i}>
            <StyledCardHeader
              action={
                <IconButton 
                aria-label='Edit'
                style={{ marginRight: '0px' }} // Adjust the margin to move the icon to the left
              >
                <ThreeDots />
              </IconButton>
              }
              style={{ backgroundColor: headerBackgroundColor }}
              title={
                <>
                  {sensorName}<br />
                  {sensor.location || 'Unknown Location'}<br />
                  ID: {sensor.sensor_id || 'Unknown ID'}
                </>
              }
            />
            <CardContent>
              <List dense disablePadding>
                <StyledListItem>
                  <ListItemIcon>
                    <WirelessConnection color={communicationColor} aria-label="Communication"/>
                  </ListItemIcon>
                  <ListItemText>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <span style={{ marginRight: '8px' }}>
                        {sensor.communication ? 'Online' : 'Offline'}
                      </span>
                      {!sensor.communication && (
                        <>
                          {sensor.hours_from_broadcast && (
                            <span style={{ fontSize: '0.75rem', marginRight: '4px' }}>
                              {sensor.hours_from_broadcast} hrs
                            </span>
                          )}
                          {lastBroadcast && (
                            <span style={{ fontSize: '0.75rem' }}>
                              ({lastBroadcast})
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </ListItemText>
                </StyledListItem>
                <StyledListItem>
                  <ListItemIcon>
                    <CableConnection color={connectedColor} aria-label="Connected"/>
                  </ListItemIcon>
                  <ListItemText>
                    <span>{sensor.connected ? 'Sensor connected' : 'Sensor disconnected'}</span>
                  </ListItemText>
                </StyledListItem>
                <StyledListItem>
                  <ListItemIcon>
                    <SensorIn color={inConcreteColor} aria-label="InConcrete"/>
                  </ListItemIcon>
                  <ListItemText>
                    <span>{sensor.in_concrete ? 'In concrete' : 'Not in concrete'}</span>
                  </ListItemText>
                </StyledListItem>
                <StyledListItem>
                  <ListItemIcon>
                    <Battery color={batteryColor}/>
                  </ListItemIcon>
                  <ListItemText>
                    <span>{batteryValue != null && batteryValue !== 0 && batteryValue}{batterySymbol}</span>
                  </ListItemText>
                </StyledListItem>
              </List>
            </CardContent>
          </StyledProjectCard>
        );
      })}
    </Box>
  );
};

const HubDetails = ({ hubs, anchorEl, selectedHubId, handleClick, handleEditClick, handleDeleteClick, handleClose }) => {
  const { formatDate } = useDateTimeFormat();

  return (
    <Box>
      {hubs.map((hub, i) => {
        const defaultColor = '#CECECE';
        const connectedColor = hub.usb_connected ? '#009999' : defaultColor;
        const communicationColor = hub.communication ? '#009999' : '#bc544b';
        const displayLastBroadcast = formatDate(hub.last_broadcast, 'time');
        const lastBroadcast = displayLastBroadcast !== 'N/A' ? displayLastBroadcast : '';
        const batteryVoltage = hub.central_battery ?? 'undefined';
        const batteryColor = batteryVoltage === null || batteryVoltage === 'undefined' ? defaultColor : '#696868';
        //is_low_battery

        return (
          <StyledProjectCard key={i}>
            <StyledCardHeader
              action={
                <>
                  <IconButton 
                    aria-label='Options'
                    onClick={(event) => handleClick(event, hub.hub_ti_id)}
                    style={{ marginRight: '0px' }}
                  >
                    <ThreeDots />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedHubId === hub.hub_ti_id}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                    <StyledMenuItem onClick={handleEditClick}>
                      <span><EditIcon /></span> Edit Hub
                    </StyledMenuItem>
                    <StyledMenuItem onClick={e => {
                        handleDeleteClick(selectedHubId);
                      }}>
                      <span><CloseIcon /></span> Delete Hub
                    </StyledMenuItem>
                  </Menu>
                </>
              }
              //style={{ backgroundColor: hub.active ? undefined : '#CECECE' }}
              title={
                <>
                  {hub.hub_number}&nbsp;{hub.hub_name || 'Hub'}<br />
                  {hub.location || 'Unknown Location'}<br />
                  TI ID: {hub.hub_ti_id || 'Unknown ID'}
                </>
              }
            />
            <CardContent>
              <List dense disablePadding>
                <StyledListItem>
                  <ListItemIcon>
                    <WirelessConnection color={communicationColor} aria-label="Communication"/>
                  </ListItemIcon>
                  <ListItemText>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <span style={{ marginRight: '8px' }}>
                        {hub.communication ? 'Online' : 'Offline'}
                      </span>
                      {!hub.communication && (
                        <>
                          {hub.hours_from_broadcast && (
                            <span style={{ fontSize: '0.75rem', marginRight: '4px' }}>
                              {hub.hours_from_broadcast} hrs
                            </span>
                          )}
                          {lastBroadcast && (
                            <span style={{ fontSize: '0.75rem' }}>
                              ({lastBroadcast})
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </ListItemText>
                </StyledListItem>
                <StyledListItem>
                  <ListItemIcon>
                    <CableUSB color={connectedColor} aria-label="USB"/>
                  </ListItemIcon>
                  <ListItemText>
                    <span>
                      {hub.usb_connected ? 'USB Connected' : 'USB Disconnected'}
                    </span>
                  </ListItemText>
                </StyledListItem>
                <StyledListItem>
                  <ListItemIcon>
                    <Battery color={batteryColor}/>
                  </ListItemIcon>
                  <ListItemText>
                    <span>{batteryVoltage != null && batteryVoltage !== 0 && batteryVoltage} mV</span>
                  </ListItemText>
                </StyledListItem>
              </List>
            </CardContent>
          </StyledProjectCard>
          
        );
      })}
    </Box>
  );
};

const ProjectStatus = () => {
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();
  const getTechnicalScreen = useProjectStore((state) => state.getTechnicalScreen);
  const technicalScreenData = useProjectStore((state) => state.technicalScreenData);
  const { deleteHub } = useProjectStore();
  const [view, setView] = useState<'hubs' | 'sensors'>('sensors');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedHubId, setSelectedHubId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (projectId) {
      getTechnicalScreen(Number(projectId));
    }
  }, [projectId, getTechnicalScreen]);

  const handleViewChange = (event: React.MouseEvent<HTMLElement>, newView: 'hubs' | 'sensors') => {
    setView(newView);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, hubId: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedHubId(hubId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedHubId(null);
  };

  const handleEditClick = () => {
    navigate(
      routesDetails.editHub(
        selectedHubId,
        projectId
      )
    );
  };

  const handleDeleteClick = (hubId: string) => {
    setIsModalOpen(true);
    setSelectedHubId(hubId);
  };

  const handleConfirmDelete = async (selectedHubId: string) => {
    await deleteHub(selectedHubId, async () => {
      await getTechnicalScreen(Number(projectId));
      setIsModalOpen(false);
      setSelectedHubId(undefined);
      toast.success(t('hubDeletedSuccessfully'), {
        autoClose: 1000,
      });
    });
  };

  return (
    <Page
      handleArrowClick={() => navigate(ROUTES.HOME)}
      navTitle={'Technical Status'}
    >
      <Box sx={{ margin: '1px' }}>
        <FlexWrapper
          alignItems='center'
          justifyContent='center'
          sx={{ marginTop: '20px', marginBottom: '8px' }}
        >
          <StyledToggleButton
            value={view}
            exclusive
            onChange={handleViewChange}
            aria-label='view toggle'
          >
            <ToggleButton value='sensors' aria-label='Sensors'>
              <Sensor />
              <span style={{ marginLeft: '8px' }}>Devices</span>
            </ToggleButton>
            <ToggleButton value='hubs' aria-label='Hubs'>
              <Hub />
              <span style={{ marginLeft: '8px' }}>Hubs</span>
            </ToggleButton>
          </StyledToggleButton>
        </FlexWrapper>
    
        {technicalScreenData.length > 0 ? (
          view === 'sensors' ? (
            technicalScreenData.map((data, index) => (
              <SensorDetails key={`sensor-${index}`} sensors={data.project_sensors} />
            ))
          ) : (
            technicalScreenData.map((data, index) => (
              <HubDetails 
                key={`hub-${index}`} 
                hubs={data.project_hubs}
                anchorEl={anchorEl}
                selectedHubId={selectedHubId} 
                handleClick={handleClick} 
                handleEditClick={handleEditClick} 
                handleDeleteClick={() => {
                  handleDeleteClick(selectedHubId); // Pass selectedHubId directly
                }}
                handleClose={handleClose}/>
            ))
          )
        ) : (
          <Typography>No technical screen data available.</Typography>
        )}
      </Box>
      <PopConfirm
        isOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onConfirm={() => {
          handleConfirmDelete(selectedHubId); // Pass selectedHubId directly
          setIsModalOpen(false); // Close the modal
        }}
        title={'Delete Hub'}
        subTitle={'Are you sure you want to delete this hub? This action cannot be undone.'}
      />
    </Page>
  );
};

export default ProjectStatus;
