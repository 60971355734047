import { useEffect, useRef, memo, useState } from 'react';
import QrScanner from 'qr-scanner';
import { toast } from 'react-toastify';
import { Box, Typography, styled } from '@mui/material';
import { CameraBorders, QRCode, DeviceIcon, BananaSensor } from '../icons';
import { t } from 'i18next';
import { LoadingOverlay } from '..';

export const StyledQRCodeWrap = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[500],
  height: '251px',
  borderRadius: '8px',
  overflow: 'hidden',
  position: 'relative',
  '& video': {
    opacity: `1 !important`,
    width: '100% !important',
    height: '100% !important',
  },
}));

export const QRIconWrap = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
});

export const CameraBorderWrap = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '50%',
  height: '90%',
  margin: '0 auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%);',

  '&:after , &:before': {
    content: '""',
    top: '0',
    border: `1px solid ${theme.palette.black.main} `,
    width: '70px',
    height: '70px',
    position: 'absolute',
  },
  '&:after': {
    left: '0',
    borderWidth: '1px 0 0 1px',
  },
  '&:before': {
    right: '0',
    borderWidth: '1px 1px 0 0 ',
  },
}));
export const BottomCameraBorder = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '0',
  left: '0',
  width: '100%',
  '&>div': {
    '&:after': {
      content: '""',
      bottom: '0',
      left: '0',
      border: `1px solid ${theme.palette.black.main} `,
      width: '70px',
      height: '70px',
      position: 'absolute',
      borderWidth: '0 0 1px 1px',
    },
    '&:before': {
      content: '""',
      bottom: '0',
      right: '0',
      border: `1px solid ${theme.palette.black.main} `,
      width: '70px',
      height: '70px',
      position: 'absolute',
      borderWidth: ' 0 1px 1px 0 ',
    },
  },
}));
interface QrCodeScannerProps {
  onScan: (data: string) => boolean;
  isLoading: boolean;
  isHub?: boolean;
  isBanana?: boolean;
}

const QrCodeScanner = memo(
  ({ onScan, isLoading = false, isHub = false, isBanana = false} : QrCodeScannerProps) => {
    const [camera_to_use, setMainCamera] = useState(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const qrRef = useRef<QrScanner>(null);  
    const checkCameraAccess = async () => {
      (navigator as Navigator & { mediaDevices?: MediaDevices }).mediaDevices?.enumerateDevices() 
      .then(async (devices) => {
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        if (videoDevices.length > 0) {
          console.log("Available video devices:", videoDevices);
          try {
    
            let camera = await get_back_camera();
            start_camera(camera);

            
          } catch (error) {
            toast.info(
              'Please allow the camera to scan the QRcode then reload the page'
            );
          }

        } else {
          console.warn("No video devices found.");
        }
      })
      .catch((error) => {
        console.error("Error enumerating devices:", error);
      });
      
    };

    const get_back_camera = async () => {
      const x = await QrScanner.listCameras();
      try{
        let ret = x.filter(x=>x.label.includes('back'))[0];
        if(ret.id){
          setMainCamera(ret.id);
          console.log(ret)
          setMainCamera(ret.id);
          return new Promise((resolve) => {resolve(ret.id)});
        }
        else{
          setMainCamera('didnt_find_camera');
          return new Promise((resolve) => {resolve('didnt_find_camera')});
        }
      }catch(error){
        console.log(error);
        setMainCamera('didnt_find_camera');
        return new Promise((resolve) => {resolve('didnt_find_camera')});
      }     
    }

    useEffect(() => { 
      checkCameraAccess();
    }, []);

    const start_camera = (cameara) => {
      // eslint-disable-next-line no-lone-blocks
      {
        console.log(cameara);
        QrScanner.hasCamera().then(hasCamera => {
          if (hasCamera && !qrRef.current && cameara) {
            qrRef.current = new QrScanner(
              videoRef.current,
              (result: QrScanner.ScanResult) => {
                if (result) {
                  const isValid = (result?.data);
                  if (isValid) {
                    toast.dismiss();
                    qrRef.current.stop();
                    onScan(result.data.toString())
                  }
                }
              },
              {
                highlightScanRegion: true,
              }
            );

            if(cameara === 'didnt_find_camera'){
              qrRef.current.start();
            }else{ // if didn't find for some reason, try in the regular way and hope its not going to be selfy camera
              qrRef.current.setCamera(cameara);
              qrRef.current.start();
            }
              
            
            
          }
        });
  
          return () => {
            if (qrRef.current) {
              // eslint-disable-next-line react-hooks/exhaustive-deps
              isLoading = false;
              qrRef.current.stop();
              qrRef.current.destroy()
            }
          };
      }
    };

    return (
      <>
        <StyledQRCodeWrap>
          {isLoading && <LoadingOverlay />}
          <Box
            sx={{
              position: 'relative',
              height: '100%',
            }}
          >
            <QRIconWrap>
              {isHub ? (
                <QRCode />
              ) : isBanana ? (
                <BananaSensor />
              ) : (
                <DeviceIcon />
              )}
            </QRIconWrap>
            {!qrRef.current && (
              <CameraBorderWrap>
                <BottomCameraBorder>
                  <div></div>
                </BottomCameraBorder>
              </CameraBorderWrap>
            )}
            <video ref={videoRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />
          </Box>
        </StyledQRCodeWrap>
        <Typography
          fontStyle='italic'
          mt='8px'
          mb='20px'
          variant='body2'
          color='common.black'
        >
          {isHub
            ? t('pleaseClickToScanTheQrCodeOnTheHub')
            : isBanana
            ? t('pleaseClickToScanTheQrCodeOnTheBanana')
            : t('pleaseClickToScanTheQrCodeOnTheSensor')}
        </Typography>
      </>
    );
  }
);

export default QrCodeScanner;
