import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { toast } from 'react-toastify';
import {
  IConcreteType,
  ICreateOrUpdatePourPayload,
  IDashboardPour,
  IEnhancedPdfDetails,
  IFinishedPourData,
  IPourDetails,
  IPourExportDetailsItem,
  IProjectDashboardDetails,
  IProjectItem,
  IProjectPourCardData,
  ITechnicalScreen,
  ISensorStatus,
  IHubStatus,
  IEditHub,
  IConcreteMix,
  IPSIConcreteType,
} from '@/models';
import { http, sleep } from '@/utils';
import { API_ENDPOINTS, LoadingKeys } from '@/constants';
import { handleApiCallInStore } from './utils';
import { t } from 'i18next';

export interface IProjectState {
  pwaVersion: any;
  ClientSensor: any[];
  ClientBananas: any[];
  pourReportData: IPourExportDetailsItem[];
  pdfDetails: IEnhancedPdfDetails;
  concreteTypes: IConcreteType[];
  psiConcreteTypes: IPSIConcreteType[];
  projectPours: IProjectPourCardData[];
  projectDetails: { name: string; id: string };
  pourDetails: IPourDetails;
  projects: IProjectItem[];
  dashboardPours: IDashboardPour[];
  technicalScreenData: ITechnicalScreen[];
  concreteMix: IConcreteMix[];
  getProjectDashboard: (projectId: string) => void;
  getPwaVersion: () => any;
  getClientSensor: () => any;
  getClientBananas: () => any;
  getConcreteTypes: (is_mpa: boolean) => void;
  getProjects: (onSuccess: (data: IProjectItem[]) => void) => void;
  // getProjectsDetails: (id: string) => void;
  getProjectPours: (projectId: string) => void;
  getPourGaugeDetails: (
    id: string,
    onSuccess: (data: IPourDetails) => void
  ) => void;
  currentExportPourId: number;
  markPourAsDone: (
    projectId: string,
    id: number,
    onSuccess: () => void
  ) => void;
  exportPourData: (
    pourId: number,
    pdfDetails: IEnhancedPdfDetails,
    onSuccess: (data: IPourExportDetailsItem[]) => void
  ) => void;
  createOrUpdatePour: (data: {
    payload: ICreateOrUpdatePourPayload;
    onSuccess: () => void;
    pourId?: string;
  }) => void;
  getTechnicalScreen: (projectId: number) => void;
  deleteHub: (hubId: string, onSuccess: () => void) => void;
  editHub:(
    hubId: string,
    data: IEditHub,
    onSuccess?: (data: any) => void
  ) => void;
  getConcreteMix: (projectId: number) => void;
}

export const useProjectStore = create(
  persist<IProjectState>(
    (set, get) => ({
      ClientSensor: [],
      ClientBananas: [],
      pwaVersion: null,
      pdfDetails: undefined,
      pourReportData: [],
      pourDetails: undefined,
      sensorData: undefined,
      projectDetails: undefined,
      projectPoursCategories: [],
      projectPours: [],
      dashboardPours: [],
      concreteTypes: [],
      psiConcreteTypes: [],
      currentExportPourId: undefined,
      projects: [],
      pourSensors: {},
      technicalScreenData: [],
      concreteMix: [],
      getTechnicalScreen: (projectId: number) =>
        handleApiCallInStore(LoadingKeys.TechnicalScreen, async () => {
          try {
            const { data } = await http.get<{ project_sensors: ISensorStatus[]; project_hubs: IHubStatus[] }>(
              API_ENDPOINTS.project.technicalScreenData(projectId)
            );
            // Map the data to match the ITechnicalScreen interface
            const technicalScreenData: ITechnicalScreen[] = [
              {
                project_sensors: data.project_sensors,
                project_hubs: data.project_hubs
              }
            ];
      
            set(() => ({ technicalScreenData }));
          } catch (error) {
            console.error("Error fetching technical screen data:", error);
          }
        }),
      deleteHub: (hubId: string, onSuccess) =>
        handleApiCallInStore(LoadingKeys.DeleteHub, async () => {
          await http.delete(API_ENDPOINTS.project.deleteHub(hubId));
          //toast.success('the Hub is deleted');
          onSuccess();
        }),
      editHub(hubId: string, data: any, onSuccess) {
        handleApiCallInStore(LoadingKeys.EditHub, async () => {
          await http.put<IEditHub>(API_ENDPOINTS.project.editHub(hubId), data);
          //toast.success('the Hub is updated');
          onSuccess(data);
        });
      },
      getPwaVersion: () =>
        handleApiCallInStore(LoadingKeys.pwaVersion, async () => {
          const { data } = await http.get<{ pwaVersion: any }>(
            API_ENDPOINTS.project.pwaVersion
          );
          // set(() => ({ pwaVersion: data }));
          return data +" check";
        }),
      getConcreteTypes: (is_mpa) =>
      {
        if(is_mpa){
          handleApiCallInStore(LoadingKeys.ConcreteTypes, async () => {
            const { data } = await http.get<{ concreteTypes: IConcreteType[] }>(
              API_ENDPOINTS.project.concreteTypes
            );
            set(() => ({ concreteTypes: data?.concreteTypes }));
            set(() => ({ psiConcreteTypes: [] }));
          })
        }else{
          handleApiCallInStore(LoadingKeys.psiConcreteTypes, async () => {
            const { data } = await http.get<{ concreteTypes: IPSIConcreteType[] }>(
              API_ENDPOINTS.project.psiConcreteTypes
            );
            set(() => ({ psiConcreteTypes: data?.concreteTypes }));
            set(() => ({concreteTypes:[]}))
          })
        }

      },

      getConcreteMix: (projectId: number) =>
        handleApiCallInStore(LoadingKeys.ConcreteMix, async () => {
          try {
            const { data } = await http.get<IConcreteMix[]>(
              API_ENDPOINTS.project.getConcreteMix(projectId)
            ); // Change the expected type here to IConcreteMix[]
      
            if (data && data.length > 0) {
              set((state) => ({
                ...state,
                concreteMix: data, // Update with the array of concrete mixes directly
                concreteMixError: "",
              }));
            } else {
              console.warn("No concrete mix data found for project:", projectId);
              set((state) => ({
                ...state,
                concreteMixError: "No concrete mix data found.",
              }));
            }
          } catch (error) {
            console.error("Error fetching concrete mix:", error);
            toast.error("Failed to fetch concrete mix. Please try again later.");
            set((state) => ({
              ...state,
              concreteMixError: "Failed to fetch concrete mix.",
            }));
          }
        }),   
      getProjects: onSuccess =>
        handleApiCallInStore(LoadingKeys.Projects, async () => {
          const { data } = await http.get<{ projects: IProjectItem[] }>(
            API_ENDPOINTS.project.projectspwa
          );
          set(() => ({ projects: data?.projects }));
          onSuccess(data?.projects);
        }),
      getProjectDashboard: projectId =>
        handleApiCallInStore(LoadingKeys.ProjectDashboard, async () => {
          const { data } = await http.get<IProjectDashboardDetails>(
            API_ENDPOINTS.project.projectPours(projectId)
          );
          set(() => ({ dashboardPours: data.projectInfo.all_proj_pours }));
        }),
      getClientSensor: () =>
        handleApiCallInStore(LoadingKeys.ClientSensor, async () => {
          const { data } = await http.get(
            API_ENDPOINTS.project.ClientSensor
          );
          set(() => ({ ClientSensor: data }));
      }),
      getClientBananas: () =>
        handleApiCallInStore(LoadingKeys.ClientBananas, async () => {
          const { data } = await http.get(
            API_ENDPOINTS.project.ClientBananas
          );
          set(() => ({ ClientBananas: data }));
      }),
      getProjectPours: projectId =>
        handleApiCallInStore(LoadingKeys.ProjectDashboard, async () => {
          const { data } = await http.get<IProjectDashboardDetails>(
            API_ENDPOINTS.project.projectPours(projectId)
          );
          const allPoursMap: Map<string | number, IProjectPourCardData> =
            new Map();
          set(() => ({
            projectDetails: {
              name: data?.projectInfo?.all_proj_pours?.[0]?.proj_name,
              id: projectId,
            },
          }));
          data.projectInfo.all_proj_pours
            .sort(
              (a, b) =>
                new Date(a.min_cast_start_time_utc).valueOf() -
                new Date(b.min_cast_start_time_utc).valueOf()
            )
            .forEach(pourItem => {
              const errors = [];
              data.projectInfo.all_proj_alert.forEach(alertItem => {
                if (alertItem.error && alertItem.pour_id === pourItem.id) {
                  errors.push(`${alertItem.event_type} `);
                }
              });
              allPoursMap.set(pourItem.id, {
                pour_id: pourItem.id,
                status:
                pourItem.min_cast_start_time_utc == null? 'new' : 'progressing',
                  // pourItem.highest_known_strengh === 0 ? 'new' : 'progressing',
                pour_name: pourItem.name,
                pour_start_time: pourItem.min_cast_start_time_utc,
                highest_known_strengh: Number(pourItem.highest_known_strengh),
                b_type_name: pourItem.b_type_name,
                errors,
                last_humidity: pourItem.last_humidity?.toFixed(0),
                use_humidity: pourItem.use_humidity,
                expected_finish: pourItem.expected_finish,
                finish_at: pourItem.finish_at,
              });
            });
          const finishedPourData = await http.get<IFinishedPourData[]>(
            API_ENDPOINTS.project.finishedPours(projectId)
          );
          finishedPourData.data.forEach(pourItem => {
            allPoursMap.set(pourItem.pour_id, {
              ...pourItem,
              pour_id: Number(pourItem.pour_id),
              highest_known_strengh: Number(pourItem.highest_known_strengh),
              status: 'finished',
              errors: pourItem?.errors?.map(errorItem => errorItem.message),
              last_humidity:0,
              use_humidity: pourItem.use_humidity,
              expected_finish: pourItem.expected_finish,
              finish_at: pourItem.finish_at,
            });
          });

          set(() => ({
            projectPours: Array.from(allPoursMap.values()),
          }));
        }),

      markPourAsDone: (projectId, id, onSuccess) =>
        handleApiCallInStore(LoadingKeys.MarkPourAsDone, async () => {
          await http.post(API_ENDPOINTS.project.markPourAsDone(projectId, id));
          toast.success(t('thePourMarkedAsDoneSuccessfully'));
          onSuccess();
          get().getProjectPours(projectId);
        }),
      exportPourData: (pourId, pdfDetails, onSuccess) =>
        handleApiCallInStore(LoadingKeys.ExportPourDetails, async () => {
          set(() => ({
            currentExportPourId: pourId,
          }));
          const { data } = await http.get<IPourExportDetailsItem[]>(
            API_ENDPOINTS.project.pourDetails(pourId)
          );
          set(() => ({
            pourReportData: data,
            pdfDetails,
          }));
          await sleep(600);
          await onSuccess(data);
        }),
      createOrUpdatePour: ({ payload, onSuccess, pourId }) =>
        handleApiCallInStore(LoadingKeys.CreateOrUpdatePour, async () => {
          if (pourId !== undefined) {
            await http.put<ICreateOrUpdatePourPayload>(
              API_ENDPOINTS.project.updatePour(pourId),
              payload
            );
          } else {
            await http.post<ICreateOrUpdatePourPayload>(
              API_ENDPOINTS.project.createPour,
              payload
            );
          }

          await onSuccess();
        }),
      getPourGaugeDetails: (id, onSuccess) =>
        handleApiCallInStore(LoadingKeys.PourGaugeDetails, async () => {
          const { data } = await http.get<
            { snapshot: IPourDetails }[] | { pour_data: IPourDetails[] }
          >(API_ENDPOINTS.project.pourGaugeDetails(id));

          let pourDetailsData = {} as IPourDetails;
          if ('pour_data' in data) {
            pourDetailsData = data?.pour_data?.[0];
          } else {
            pourDetailsData = data?.[0]?.snapshot;
            pourDetailsData.b_type =
              data?.[0]?.snapshot.concretes?.[0]?.concrete_type;
          }

          set(() => ({
            pourDetails: pourDetailsData,
          }));
          onSuccess(pourDetailsData);
        }),
    }),
    {
      name: 'Project-storage',
      getStorage: () => sessionStorage,
    }

  )
);
