import { useState } from 'react';
import {
  Box,
  CardContent,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Menu,
  useTheme,
} from '@mui/material';
import {
  ArrowRight,
  Concrete,
  Edit,
  Location,
  MenuIcon,
  BatteryPercentage,
  BananaSensor,
  DeviceIcon,
} from '../icons';
import {
  StyledCardHeader,
  StyledListItem,
  StyledProjectCard,
} from '../project-card/project-card.styled';
import { t } from 'i18next';
import { routesDetails } from '@/constants';
import { useNavigate } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import { StyledMenuItem } from '../pour-card';
import { useUnitType,useCubeOrCylinder } from '@/hooks';
import { mpaToPsi,cubeToCelinder } from '@/utils';
import InfoIcon from '@mui/icons-material/Info';

// red, yellow, green based on the percentage
const getBatteryColorPercentage = (percentage) => {
  if (percentage === null || percentage === undefined) {
    return '#CECECE'; // Default color if no battery value
  }
  if (percentage < 40) {
    return '#bc544b'; // Red color for battery < 40%
  } else if (percentage < 80) {
    return '#cda984'; // Yellow color for 40% <= battery < 80%
  } else {
    return '#009999'; // Green color for battery >= 80%
  }
};

interface IProjectCardProps {
  isDisabled: boolean;
  pourId: string;
  pourName: string;
  deviceName: string;
  location: string;
  sensor_id: string;
  banana_id:string;
  handleDeleteSensorClick: (sensorId: string) => void;
  projectId: string;
  errors?: any[]; // Add errors as a prop
  device_battery: any;
  last_strength: any;
}

const SensorCard = ({
  pourId,
  isDisabled,
  projectId,
  pourName,
  deviceName,
  location,
  sensor_id,
  banana_id,
  device_battery,
  last_strength,
  handleDeleteSensorClick,
  errors = [], // Default to an empty array if no errors are passed
}: IProjectCardProps) => {
  const theme = useTheme();
  const { isMetric } = useUnitType();
  const { isCylinder } = useCubeOrCylinder();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const batteryValueSymbol = device_battery == null ? '∅' : `${device_battery}%`;
  const batteryColor = device_battery == null ? '#CECECE' : getBatteryColorPercentage(device_battery);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: any) => {
    e?.stopPropagation?.();
    setAnchorEl(null);
  };

  // Filter errors related to the current sensor
  const sensorErrors = errors.filter(error =>
    error.includes(`id: ${sensor_id}`)
  );
  

  return (
    <>
      <Box sx={{ overflow: 'auto', flex: 1 }}>
        <StyledProjectCard
          onClick={() =>
            navigate(
              routesDetails.Sensor(
                projectId,
                pourId,
                pourName,
                sensor_id,
                deviceName,
                isDisabled
              ),
              {
                state: { errors }, // Pass errors through state
              }
            )
          }
        >
          <StyledCardHeader
            action={
              <IconButton aria-label='view Project'>
                <ArrowRight />
              </IconButton>
            }
            title={
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <span>{deviceName}</span>
                {device_battery !== null && (
                  <BatteryPercentage
                    value={device_battery}
                    fillColor={batteryColor}
                    batterySymbol={batteryValueSymbol}
                    strokeColor="white"
                  />
                )}
              </div>
            }
          />

          <CardContent sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                top: '0',
                right: '0',
                zIndex: '99',
              }}
            >
              <IconButton
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                aria-label='options'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <StyledMenuItem
                  disabled={isDisabled}
                  onClick={e => {
                    e.stopPropagation();
                    handleDeleteSensorClick(sensor_id);
                    handleClose(e);
                  }}
                >
                  <Box
                    component='span'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      '& svg': {
                        width: '15px',
                        height: '15px',
                        fill: 'red',
                      },
                    }}
                  >
                    <CloseIcon />
                  </Box>
                  {t('delete')}
                </StyledMenuItem>
                <StyledMenuItem
                  disabled={isDisabled}
                  onClick={e => {
                    e.stopPropagation();
                    navigate(routesDetails.editSensor(sensor_id,projectId, pourId));
                    handleClose(e);
                  }}
                >
                  <span>
                    <Edit />
                  </span>
                  {t('editSensor')}
                </StyledMenuItem>
              </Menu>
            </Box>
            <List dense disablePadding>
              <StyledListItem>
                <ListItemIcon>
                  <Location />
                </ListItemIcon>
                <ListItemText>
                  <span>{`${t('location')} :`}</span>
                  {location}
                </ListItemText>
              </StyledListItem>
              <StyledListItem>
                <ListItemIcon
                  sx={{
                    '& path': {
                      fill: theme.palette.primary.main,
                      fillOpacity: 1,
                    },
                  }}
                >
                  <DeviceIcon width="22px" height="18px" />
                </ListItemIcon>
                <ListItemText>
                  <span>{`${t('sensorId')} :`}</span>
                  {sensor_id}
                </ListItemText>
              </StyledListItem>
              {banana_id && (
              <StyledListItem>
                <ListItemIcon
                  sx={{
                    '& path': {
                      fill: theme.palette.primary.main,
                      fillOpacity: 1,
                    },
                  }}
                >
                <BananaSensor width="20px" height="32px" />
                </ListItemIcon>

                  <ListItemText>
                    <span>{`${t('bananaId')} :`}</span>
                    {banana_id}
                  </ListItemText>

              </StyledListItem>
              )}
              <StyledListItem>
                <ListItemIcon
                  sx={{
                    '& path': {
                      fill: theme.palette.primary.main,
                      fillOpacity: 1,
                    },
                  }}
                >
                  <Concrete />
                </ListItemIcon>
                <ListItemText>
                  <span>{`${t('lastStrength')} :`}</span>
                  {last_strength?
                  isMetric?
                       isCylinder?
                        `${cubeToCelinder(last_strength)?.toFixed(2)} ${t('mpa')}` 
                        : `${last_strength?.toFixed(2)} ${t('mpa')}` 
                  :isCylinder?
                       `${mpaToPsi(cubeToCelinder(last_strength))?.toFixed(0)} ${t('psi')}` 
                  :`${mpaToPsi(last_strength)?.toFixed(0)} ${t('psi')}`
                  : `${t('NA')}`}
                </ListItemText>
              </StyledListItem>
              <StyledListItem>
                <ListItemIcon>
                  {sensorErrors.length > 0 && <InfoIcon sx={{ color: 'red' }} />}
                </ListItemIcon>
                <ListItemText>
                  {sensorErrors.length > 0 && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        overflow: 'visible',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        maxWidth: '100%',
                      }}
                    >
                      {sensorErrors.map((error, index) => {
                        const match = error.match(/id:\s*\S+\s*(.*)/);
                        const messagePart = match ? match[1] : error;
                                      
                        return (
                          <span key={index}>
                            {messagePart || error}
                          </span>
                        );
                      })}
                    </Box>
                  )}
                </ListItemText>
              </StyledListItem>
            </List>


            
          </CardContent>
        </StyledProjectCard>
      </Box>
    </>
  );
};

export default SensorCard;
