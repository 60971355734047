import { Box, IconButton, Menu, SxProps, Theme, styled } from '@mui/material';
import React from 'react';
import { Hub, Plus, Pour, Sensor } from '../icons';
import { StyledMenuItem } from '../pour-card';
import { t } from 'i18next';
import { useNavigate } from 'react-router';
import { routesDetails } from '@/constants';

export const StyledAddBtn = styled(IconButton)(({ theme, ...otherProps }) => ({
  backgroundColor: theme.palette.primary.darker,
  boxShadow:
    '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
  width: '56px',
  height: '56px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  '& svg': {
    transition: 'transform 0.3s ease-in-out',
    transform: otherProps['aria-expanded'] ? 'rotate(45deg)' : '',
  },
}));

interface FloatingAddBtnProps {
  sx?: SxProps<Theme>;
  projectId: string;
  projectName: string;
  use_banana: boolean;
  use_nfc: boolean;
}

const FloatingAddBtn = ({
  sx,
  projectId,
  projectName,
  use_banana,
  use_nfc,
}: FloatingAddBtnProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    {
      name: use_banana ? t('addSensorBananaByQR') : t('addSensorByQR'),
      onClick: () => {
        navigate(routesDetails.addSensor(projectId, undefined, true));
        handleClose();
      },
      icon: <Sensor />,
    },
    {
      name: use_banana ? t('addSensorBananaByNFC') : t('addSensorByNFC'),
      onClick: () => {
        navigate(routesDetails.addSensor(projectId));
        handleClose();
      },
      icon: <Sensor />,
      disabled: !Boolean(use_nfc),
    },
    {
      name: use_banana ? t('addSensorBananaByName') : t('addSensorByName'),
      onClick: () => {
        navigate(routesDetails.addSensorByName(projectId));
        handleClose();
      },
      icon: <Sensor />,
    },
    {
      name: t('addPour'),
      onClick: () => {
        navigate(routesDetails.addPour(projectId));
        handleClose();
      },
      icon: <Pour />,
    },
    {
      name: t('addHub'),
      onClick: () => {
        navigate(routesDetails.addHub(projectId, projectName));
        handleClose();
      },
      icon: <Hub />,
    },
  ];

  return (
    <Box sx={{ ...sx }}>
      <StyledAddBtn
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <Plus />
      </StyledAddBtn>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleClose}
      >
        {options
          .filter(option => !option.disabled)
          .map(option => (
            <StyledMenuItem key={option.name} onClick={option.onClick} disabled={option.disabled}>
              <span>{option.icon}</span>
              {option.name}
            </StyledMenuItem>
          ))}
      </Menu>
    </Box>
  );
};

export default FloatingAddBtn;
