import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/Info';
import { t } from 'i18next';
import {
  ConcreteStrengthType,
  IPdfDetails,
  IProjectPourCardData,
} from '@/models';
import {
  Box,
  Card,
  CardContent,
  ClickAwayListener,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { Check, Edit, MenuIcon, PdfExport, ProgressCircle } from '@/components';
// OVERDESIGN FEATURE - CIRCLES FOR THE SUMMIT CO2
// import Co2Circle from '@/components/co2-circle';
// import ExcessCircle from '@/components/excess-circle';
import FlexWrapper from '../flex-wrapper';
import { loadingStore, useProjectStore } from '@/stores';
import { LoadingKeys, routesDetails } from '@/constants';
import { useNavigate } from 'react-router';
import { styled } from '@mui/material/styles';
import LoadingOverlayComp from '../loading-overlay';
import { useDateTimeFormat } from '@/hooks';
import { mpaToPsi,cubeToCelinder } from '@/utils';


const getPourStrengthPercentage = (
  concreteType: ConcreteStrengthType,
  highestKnownStrength: number = 0,
  pourStartTime: any
) => {
  let percentage = 0;
  
  try {
    const concreteStrength = Number(concreteType.replace('B', ''));
    
    if (!isNaN(concreteStrength) && concreteStrength !== 0) {
      percentage = (highestKnownStrength / concreteStrength) * 100;
    }
  } catch (error) {
    toast.error('Concrete type has a wrong value')
  }
  return percentage === 0 && pourStartTime !== null ? 0.01 : percentage;
};

const getOverDesign = (
  concreteType: ConcreteStrengthType,
  highestKnownStrength: number = 0
) => {
  const baseStrength = Number(concreteType.replace('B', ''));
  return Math.max(0, highestKnownStrength - baseStrength);
};

export const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.grey.A100,
  filter: 'drop-shadow(0px 4px 8px rgba(0, 153, 153, 0.1))',
  margin: '12px 0',
  boxShadow: 'none',
  borderRadius: '12px',
  minHeight: 'auto', //
  '& .MuiCardContent-root': {
    padding: '9px 16px',
    color: theme.palette.common.black,
  },
}));
export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.black.darker,
  minHeight: 'auto',
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
}));

const getErrorWithoutId = (error) => {
  // Create a regular expression to match the pattern "id: " followed by digits
  const idRegex = new RegExp(/with id: \d+/); 
  // Replace the matched part with an empty string and trim any whitespace
  return error.replace(idRegex, '').trim(); 
};

interface PourCardProps {
  pourDetails: IProjectPourCardData;
  handleOpenPopConfirmModal: (pourId: number) => void;
  handlePdfExport: (pdfDet: IPdfDetails, pourId: number) => void;
  projectId: string;
  isMetric: boolean;
  isCylinder: boolean;
  projectName: string;
  last_humidity: any;
  use_humidity: any;
}

const PourCard = ({
  pourDetails,
  handleOpenPopConfirmModal,
  projectId,
  isMetric,
  isCylinder,
  projectName,
  last_humidity,
  use_humidity,
  handlePdfExport,
}: PourCardProps) => {
  const getStrength = useCallback(
    (strength: number) => {
      if (!isMetric) {
        if(isCylinder){
          return  mpaToPsi(cubeToCelinder(strength))?.toFixed()
        }
        return mpaToPsi(strength)?.toFixed();
      } else {
        if(isCylinder){
          return  parseInt(cubeToCelinder(strength)?.toFixed());
        }
        return parseInt(strength?.toFixed());
      }
    },
    [isMetric,isCylinder]
  );
  const navigate = useNavigate();
  const { formatDate } = useDateTimeFormat();
  const { currentExportPourId } = useProjectStore();
  const { getLoading } = loadingStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const {
    pour_id,
    pour_name,
    errors,
    pour_start_time,
    highest_known_strengh = 0,
    b_type_name,
    status,
    expected_finish,
    finish_at,
  } = pourDetails || {};
  const overDesignNumber = getOverDesign(b_type_name, highest_known_strengh) ?? 0;
  // OVERDESIGN FEATURE - CIRCLES FOR THE SUMMIT CO2
  //const polutionCo2 = 6.12 * (getOverDesign(b_type_name, highest_known_strengh) ?? 0);

  const formattedErrors = errors?.map(getErrorWithoutId) || [];  
  
  return (
    <>
      <StyledCard>
        {currentExportPourId === pour_id &&
          getLoading(LoadingKeys.ExportPourDetails) && <LoadingOverlayComp />}

        <CardContent>
          <FlexWrapper alignItems='center' justifyContent='space-between'>
            <Box>
              <Typography variant='h6' component='h5' color='common.black'>
                {pour_name}
              </Typography>
              <Typography variant='body2' color='common.black'>{`${t(
                'concrete'
              )}: ${b_type_name}`}</Typography>
              <Typography variant='body2' color='common.black'>{`${t(
                'pourTime'
              )}: ${formatDate(pour_start_time, 'time')}`}</Typography>
              <Typography variant="body2" color="common.black">
                {expected_finish && overDesignNumber === 0 ? (
                  <>
                    {`${t('expectedFinish')}: `}
                    {formatDate(expected_finish, 'time')}
                  </>
                ) : finish_at && overDesignNumber > 0 ? (
                  <>
                    {`${t('finished')}: `}
                    {formatDate(finish_at, 'time')}
                  </>
                ) : overDesignNumber === 0 ? (
                  `${t('expectedFinish')}: N/A`
                ) : (
                  `${t('finished')}: N/A`
                )}
              </Typography>

              {errors && errors.length > 0 && (
                <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setTooltipOpen(false)}
                    open={tooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    arrow
                    title={formattedErrors.map(error => (
                      <>
                        {error} <br />
                      </>
                    ))}
                    placement='top-start'
                  >
                    <IconButton
                      sx={{ color: 'red' }}
                      onClick={() => setTooltipOpen(true)}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
              )}
            </Box>
            
            <Box sx={{ alignSelf: 'flex-start' }}>
              <IconButton
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                aria-label='options'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <StyledMenuItem
                  data-testid="mark-as-done-menu-item"
                  disabled={status === 'finished'}
                  onClick={() => {
                    handleOpenPopConfirmModal(pour_id);
                    handleClose();
                  }}
                >
                  <span>
                    <Check />
                  </span>
                  {t('markAsDone')}
                </StyledMenuItem>
                
                <StyledMenuItem
                  data-testid="pdf-export-menu-item"
                  onClick={() => {
                    handlePdfExport(
                      {
                        pourDataName: pour_name,
                        concreteType: b_type_name,
                        pourStartTime: pour_start_time,
                      },
                      pour_id
                    );
                    handleClose();
                  }}
                >
                  <span>
                    <PdfExport />
                  </span>
                  {t('pdfExport')}
                </StyledMenuItem>
                <StyledMenuItem
                  data-testid="edit-menu-item"
                  disabled={status === 'finished'}
                  onClick={() => {
                    navigate(routesDetails.editPour(projectId, pour_id));
                    handleClose();
                  }}
                >
                  <span>
                    <Edit />
                  </span>
                  {t('editPour')}
                </StyledMenuItem>
              </Menu>
            </Box>
          </FlexWrapper>
          <Box sx={{mb:3}}>
          <FlexWrapper
              alignItems="flex-end"  // Align components to the bottom
              justifyContent="center" // Center them horizontally
              sx={{
                gap: '12px',  // Adjust the gap between the components (you can modify this value)
              }}
            >
              {/* <Co2Circle percentage={100} contentNumber={polutionCo2} contentUnit={`${t('co2')}`}  title='Waste' /> */}
              <ProgressCircle
                data-testid="progress-circle"
                handleClick={() =>
                  navigate(
                    routesDetails.Sensors(
                      projectId,
                      pour_name,
                      String(pour_id),
                      status === 'finished'
                    ),
                    {
                      state: { errors }, // Pass errors through state
                    }
                  )
                }
                contentUnit={isMetric ? t('mpa') : t('psi')}
                contentNumber={getStrength(highest_known_strengh) as any}
                percentage={getPourStrengthPercentage(
                  pourDetails.b_type_name,
                  highest_known_strengh,
                  pour_start_time
                )}
                last_humidity={last_humidity}
                use_humidity={use_humidity}
              />
              {/* <ExcessCircle percentage={100} contentNumber={overDesignNumber} contentUnit={`${t('mpa')} `} title='Overdesign'/> */}
            
          </FlexWrapper>
          </Box>
        </CardContent>
      </StyledCard>
    </>
  );
};

export default PourCard;