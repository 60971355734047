export enum ROUTES {
  HOME = '/',
  NOT_FOUND = '*',
  LOGIN = '/login',
  PROJECTS = '/projects',
  PROJECT = '/projects/:id',
  PROJECT_STATUS = '/projects/status/:projectId', // Adjusted this line
  ADD_POUR = '/add-pour/:projectId',
  EDIT_POUR = '/edit-pour/:projectId/:pourId',
  EDIT_SENSOR = '/edit-sensor/:projectId/:pourId/:sensorId',
  ADD_HUB = '/add-hub/:projectName/:projectId',
  EDIT_HUB = '/edit-hub/:projectId/:hubId',
  SENSORS = '/sensors/:projectId/:pourName/:pourId',
  SENSOR = '/sensors/sensor-details/:projectId/:pourName/:pourId/:sensorName/:sensorId',
  ADD_SENSOR = '/add-sensor/:projectId',
  ADD_SENSOR_BY_NAME = '/add-sensor-by-name/:projectId',
  SETTINGS = '/settings',
  CHARTS = '/charts',
}

export const routesDetails = {
  addPour: (projectId: string) => `/add-pour/${projectId}`,
  editPour: (projectId: string, pourId: number) =>
    `/edit-pour/${projectId}/${pourId}`,
  editSensor: (sensorId: string,projectId: string, pourId: string) =>
    `/edit-sensor/${projectId}/${pourId}/${sensorId}`,
  project: (projectId: string, isOneProject?: boolean) =>
    `/projects/${projectId}?single=${isOneProject ? 'true' : 'false'}`,
  Sensors: (
    projectId: string,
    pourName: string,
    pourId: string,
    isDisabled: boolean
  ) => `/sensors/${projectId}/${pourName}/${pourId}?isDisabled=${isDisabled}`,
  Sensor: (
    projectId: string,
    pourId: string,
    pourName: string,
    sensorId: string,
    sensorName: string,
    isDisabled: boolean
  ) =>
    `/sensors/sensor-details/${projectId}/${pourName}/${pourId}/${sensorName}/${sensorId}/?isDisabled=${isDisabled}`,
  addHub: (projectId: string, projectName: string) =>
    `/add-hub/${projectName}/${projectId}`,
  editHub: (hubId: string,projectId: string) =>
    `/edit-hub/${projectId}/${hubId}`,
  addSensor: (projectId: string, pourId?: string, isWithQR?: boolean) => {
    let url = `/add-sensor/${projectId}`;
    if (pourId) {
      url += `?pourId=${pourId}`;
    }
    if (isWithQR) {
      url += (pourId ? '&' : '?') + `withQR=true`;
    }
    return url;
  },
  addSensorByName: (projectId: string, pourId?: string) =>
  pourId !== undefined
    ? `/add-sensor-by-name/${projectId}?pourId=${pourId}`
    : `/add-sensor-by-name/${projectId}`,
  ProjectStatus: (projectId: string) => `/projects/status/${projectId}`, // Adjusted this line
};
